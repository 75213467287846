(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/griditem-draw-racing/assets/javascripts/render-component.js') >= 0) return;  svs.modules.push('/components/banner_layouts/griditem-draw-racing/assets/javascripts/render-component.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const isServer = typeof exports === 'object';
const trinidad = isServer ? require('trinidad-core').core : svs.core;
const getLogger = isServer ? trinidad.logger : svs.core.log.getLogger;
const logger = getLogger('banner_layouts:griditem-draw-racing');
const currencyInMillions = svs.isServer ? trinidad.components.require('utils', 'format').api.CurrencyInMillions : svs.utils.format.CurrencyInMillions;
const {
  getFormattedDate,
  getFormattedTime
} = isServer ? trinidad.components.require('lb-utils', 'helper-formatdate').api : svs.components.lbUtils.helpers.formatDate;
const {
  productIds
} = isServer ? trinidad.components.require('utils', 'products') : svs.utils.products;
const {
  getRacingClassName
} = isServer ? trinidad.components.require('utils', 'racing') : svs.utils.racing;
const {
  getProductBrand
} = isServer ? trinidad.components.require('lb-utils', 'brand-mapping').api : svs.components.lbUtils.brandMapping.fn;
const {
  TTLCacheAsync
} = isServer ? require('../../../../lb-utils/ttl-cache/controller.js') : svs.components.lbUtils.ttlCache;
const ONE_MINUTE = 60 * 1000;
const HIGH_FIVE_USER_PRODUCT = 'Rx5io';
const TAGLINE_JACKPOT = 'ca';
const TIMEOUT_FOR_JUPITER_CALLS = 3000;

async function getOpenDraw(_ref) {
  var _meeting$track;
  let {
    req
  } = _ref;
  const path = '/racing/1/racecard/nexthighfive';
  const response = await trinidad.jupiter.callAsync({
    method: 'GET',
    path,
    req
  });
  const {
    error,
    race
  } = isServer ? response : response.response;
  const {
    draws: currentDraws,
    raceId,
    startTimeTentative,
    meeting,
    countryCode
  } = race;
  const productName = getRacingClassName(productIds.RACING, req);
  if (error) {
    logger.info("API-error while listing nexthighfive (product ".concat(productName, ", \"").concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
    return {};
  }
  if (!Array.isArray(currentDraws) || currentDraws.length === 0) {
    logger.info("Missing \"draws\" in response from nexthighfive-API (product ".concat(productName, ")"));
    return {};
  }
  const currentDraw = currentDraws.find(draw => draw.userProduct === HIGH_FIVE_USER_PRODUCT);
  const trackName = meeting === null || meeting === void 0 || (_meeting$track = meeting.track) === null || _meeting$track === void 0 ? void 0 : _meeting$track.trackName;
  return {
    currentDraw,
    raceId,
    startTimeTentative,
    trackName,
    countryCode
  };
}

function formatCurrencyTest(amount) {
  return parseFloat(currencyInMillions(amount).replace(',', '.').replace(' ', ''));
}

function formatAmountString(amount) {
  return "".concat(currencyInMillions(amount), " miljon").concat(formatCurrencyTest(amount) !== 1 ? 'er' : '', " kr");
}

async function fetchJackpots(_ref2) {
  var _jackpot$jackpots;
  let {
    raceId,
    req
  } = _ref2;
  const path = '/racing/1/race/jackpots';
  const response = await trinidad.jupiter.callAsync({
    method: 'GET',
    path,
    req
  });
  const {
    error,
    jackpots
  } = isServer ? response : response.response;
  if (error) {
    logger.info("API-error while listing racing jackpots  (raceId ".concat(raceId, ", \"").concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
    return false;
  }
  if (!Array.isArray(jackpots) || jackpots.length === 0) {
    logger.info("Missing \"jackpots\" in response from jackpots-API (product racing)");
    return false;
  }
  const jackpot = jackpots.find(jackpot => jackpot.raceId === raceId);
  const highFiveJackpot = jackpot === null || jackpot === void 0 || (_jackpot$jackpots = jackpot.jackpots) === null || _jackpot$jackpots === void 0 || (_jackpot$jackpots = _jackpot$jackpots.find(jp => jp.userProduct === HIGH_FIVE_USER_PRODUCT)) === null || _jackpot$jackpots === void 0 ? void 0 : _jackpot$jackpots.jackpot;
  return highFiveJackpot ? "".concat(TAGLINE_JACKPOT, " ").concat(formatAmountString(highFiveJackpot)) : null;
}

async function getRacingData(_ref3) {
  let {
    payload
  } = _ref3;
  const {
    product,
    req
  } = payload;
  const {
    currentDraw,
    raceId,
    startTimeTentative,
    trackName,
    countryCode
  } = await getOpenDraw({
    product,
    req
  });
  if (!currentDraw) {
    return {};
  }
  const jackpotAmountString = await fetchJackpots({
    raceId,
    req
  });
  const formattedDate = getFormattedDate(startTimeTentative, true);
  const formattedTime = getFormattedTime(startTimeTentative);
  return {
    jackpotAmountString,
    raceId,
    formattedDate,
    formattedTime,
    trackName,
    countryCode
  };
}

function getJackpotSetup(_ref4) {
  let {
    jackpotAmountString
  } = _ref4;
  let logoText;
  let title;
  let typeOfSpeechBubble;
  let speechBubbleRotation;
  if (jackpotAmountString) {
    logoText = 'Jackpot!';
    title = jackpotAmountString;
    typeOfSpeechBubble = 'speech-bubble/speech_bubble_sport_1';
    speechBubbleRotation = 'positive';
  }
  return {
    logoText,
    title,
    typeOfSpeechBubble,
    speechBubbleRotation
  };
}
const Global = Object.seal({
  racingCache: new TTLCacheAsync({
    onRefreshAsync: getRacingData,
    ttl: ONE_MINUTE,
    onError: error => {
      logger.info("racingCache.readAsync() rejected (error \"".concat(error === null || error === void 0 ? void 0 : error.message, "\")"));
    },
    timeoutMS: TIMEOUT_FOR_JUPITER_CALLS
  })
});

async function renderComponent(bannerItem, widgetInfo, position, req, res) {
  var _svs;
  const {
    product,
    bannerType,
    bannerText,
    title,
    caption
  } = bannerItem.layoutConfig;
  const hbsCompiled = isServer ? global.internalInstances.get('hbs').cache['components-banner_layouts-griditem_draw_racing-item'] :
  svs.banner_layouts.griditem_draw_racing.templates.item;
  const isAdmin = isServer ?
  req.userSession.hasRole(req.userSession.roles.INTERNAL) :
  svs.core.userSession.hasRole(svs.core.userSession.roles.INTERNAL);
  const trackingStringWithFixedStructure = [widgetInfo.widgetType, widgetInfo.trackingId || widgetInfo.widgetName, position, bannerItem.layoutType, bannerItem.title].join('/');
  const cacheKey = widgetInfo.unitTestId ? "".concat(product, "_").concat(bannerType, "_auto_").concat(widgetInfo.unitTestId) : "".concat(product, "_").concat(bannerType, "_auto");
  const cachedData = await Global.racingCache.readAsync({
    key: cacheKey,
    payload: {
      product,
      req
    }
  });
  const raceId = cachedData === null || cachedData === void 0 ? void 0 : cachedData.raceId;
  const jackpotAmountString = cachedData === null || cachedData === void 0 ? void 0 : cachedData.jackpotAmountString;
  const formattedDate = cachedData === null || cachedData === void 0 ? void 0 : cachedData.formattedDate;
  const formattedTime = cachedData === null || cachedData === void 0 ? void 0 : cachedData.formattedTime;
  const trackName = cachedData === null || cachedData === void 0 ? void 0 : cachedData.trackName;
  const countryCode = cachedData === null || cachedData === void 0 ? void 0 : cachedData.countryCode;
  if (!raceId) {
    return '';
  }
  const {
    logoText,
    title: updatedTitle,
    typeOfSpeechBubble,
    speechBubbleRotation
  } = getJackpotSetup({
    jackpotAmountString
  });
  const displayCaption = typeof caption === 'string' && caption.trim() !== '';
  const displayTrackAndTime = !displayCaption && trackName && formattedDate && formattedTime;
  const startTime = displayTrackAndTime ? "Startar ".concat(formattedDate, " kl ").concat(formattedTime) : '';
  const displayBannerText = !typeOfSpeechBubble && typeof bannerText === 'string' && bannerText.trim() !== '';
  let productBranding = getProductBrand(productIds.RACING);
  if (product.includes('-secondary')) {
    productBranding += '-secondary';
  }

  const isMobile = isServer ? req.capabilities.formFactor === 'mobile' : svs.core.detect.formfactor.mobile();
  return hbsCompiled(_objectSpread(_objectSpread({}, bannerItem.layoutConfig), {}, {
    isAdmin,
    bannerId: bannerItem.id,
    bannerType,
    displayBannerText,
    linkActionConfig: bannerItem.callToActions[0].actionConfig,
    branding: productBranding,
    features: isServer ? res.locals.features : (_svs = svs) === null || _svs === void 0 || (_svs = _svs.core) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 ? void 0 : _svs.features,
    landscapeImage: bannerItem.images.find(image => image.typeName === 'productImage'),
    portraitImage: bannerItem.images.find(image => image.typeName === 'productImageOptional'),
    trackingString: trackingStringWithFixedStructure,
    logoText,
    typeOfSpeechBubble,
    speechBubbleRotation,
    ribbonSize: isMobile ? 200 : 'responsive',
    jackpotAmountString,
    title: updatedTitle || title,
    subtitle: caption,
    countryCode,
    displayTrackAndTime,
    trackName,
    startTime
  })) || '';
}
if (isServer) {
  module.exports = {
    renderComponent
  };
} else {
  setGlobal('svs.banner_layouts.griditem_draw_racing', {
    renderComponent
  });
}

 })(window);